var dynamicRoot, devMode, mode, ga_ua, clientName = 'default';
switch(window.location.origin){
    case 'http://127.0.0.1:8100':
        dynamicRoot = 'https://arounja.test/';
        devMode = true;
        mode = 'dev';
        break;
    case 'http://localhost:8100':
        dynamicRoot = 'https://arounja.test/';
        devMode = true;
        mode = 'dev';
        break;
    case 'http://arounja.today':
    case 'http://arounja.test':
        dynamicRoot = 'http://arounja.today/';
        devMode = true;
        clientName = 'nebraska';
        mode = 'dev';
        break;
    case 'https://arounja.19ideas.com':
        dynamicRoot = 'https://arounja.19ideas.com/';
        devMode = false;
        mode = 'stage';
        break;
    case 'https://arounja.org':
        dynamicRoot = 'https://arounja.org/';
        devMode = false;
        mode = 'prod';
        break;
    case 'http://arounja.org':
        dynamicRoot = 'https://arounja.org/';
        devMode = false;
        mode = 'prod';
        break;
    case 'file://':
        dynamicRoot = 'https://arounja.org/';
        devMode = false;
        mode = 'prod';
        break;
    case 'https://chemungny.arounja.org':
        dynamicRoot = 'https://arounja.org/';
        devMode = false;
        mode = 'prod';
        clientName = 'chemung';
        break;
    case 'https://nebraska.arounja.org':
        dynamicRoot = 'https://arounja.org/';
        devMode = false;
        mode = 'prod';
        clientName = 'nebraska';
        break;
    case 'https://rockland.arounja.org':
        dynamicRoot = 'https://arounja.org/';
        devMode = false;
        mode = 'prod';
        clientName = 'rockland';
        break;
    default:
        // Otherwise if origin ends in arounja.org or arounja.test,
        // set dynamic root to location.origin
        var origin = window.location.origin;
        if(origin.endsWith('arounja.test')){
            //dynamicRoot = origin + '/';
            dynamicRoot = 'https://arounja.org/';
            devMode = false;
            mode = 'prod';
        } else if(origin.endsWith('19ideas.com')){
            dynamicRoot = origin + '/';
            devMode = false;
            mode = 'stage';
        } else {
            dynamicRoot = 'https://arounja.org/';
            devMode = false;
            mode = 'prod';
        }
}

var startUrl = '/app/home';
if(window.location.hash === '#/app/results'){
    window.location.href = '#' + startUrl;
}

angular.module('arounja', [
    'ionic',
    'ngSanitize',
    'arounja.controllers',
    'arounja.services',
    'arounja.directives',
    'arounja.filters',
    'uiGmapgoogle-maps',
    'ui.router',
    'ngStorage',
    'ngAnimate',
    'ui.bootstrap',
    'template-overrides',
    'monospaced.elastic',
    'btford.markdown',
    'angular-google-analytics',
    'star-rating',
    'lr.upload',
    'ion-autocomplete'
])

.run(['$ionicPlatform', function($ionicPlatform) {
    $ionicPlatform.ready(function() {
        // Hide the accessory bar by default (remove this to show the accessory bar above the keyboard
        // for form inputs)
        if (window.cordova && window.cordova.plugins.Keyboard) {
            cordova.plugins.Keyboard.hideKeyboardAccessoryBar(true);
            cordova.plugins.Keyboard.disableScroll(true);
        }
        if (window.StatusBar) {
            // org.apache.cordova.statusbar required
            StatusBar.styleDefault();
        }
    });
}])
.run(['SearchCriteria', function(SearchCriteria) {
    window.onbeforeunload = function() {
        SearchCriteria.saveToSession();
    };
    SearchCriteria.restoreFromSession();
}])

.config(['$ionicConfigProvider', '$httpProvider', function($ionicConfigProvider, $httpProvider) {
    $ionicConfigProvider.tabs.position('bottom');
    $ionicConfigProvider.views.transition('platform');
    var nonMobile = ['linux'];
    if (nonMobile.indexOf(ionic.Platform.platform()) > -1) {
        // $ionicConfigProvider.scrolling.jsScrolling(false);
    }
    $httpProvider.interceptors.push(['$q', '$location', '$localStorage', '$log', '$window', function($q, $location, $localStorage, $log, $window) {
        return {
            'request': function(config) {
                config.headers = config.headers || {};
                var globals = $window.localStorage.getItem('userToken') || ''; // $localStorage.userToken || '';
                //If the cookie has the CurrentUser and the token
                //add the Authorization header in each request
                if (globals) {
                    config.headers.Authorization = 'Bearer ' + globals;
                }
                return config;
            }
        };
    }]);

}])
.constant('CLIENT_NAME', clientName)
.value('airsTopLevel', {
    airs: []
})
.value('Airs', [])

.value('userInfo', {
    currentLocationMap: null
})
.config(['uiGmapGoogleMapApiProvider', function(uiGmapGoogleMapApiProvider) {
    uiGmapGoogleMapApiProvider.configure({
        key: 'AIzaSyCdOqYUvzL2lI5aJ43SYBJYYl3fkbtounE',
        //key: 'AIzaSyBLW0D7a7Ztk4d6dMzUadKZfduvjx7Ry6s',
        v: '3.20', //defaults to latest 3.X anyhow
        libraries: 'weather,geometry,visualization'
    });
}])
.config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
    $stateProvider.state('app', {
            url: '/app',
            abstract: true,
            templateUrl: 'templates/menu.html',
            controller: 'AppCtrl'
        })
        .state('app.home', {
            url: '/home',
            cache: false,
            views: {
                'menuContent': {
                    templateUrl: 'templates/home.html',
                    controller: 'HomeCtrl'
                }
            }
        })
        .state('app.results', {
            url: '/results',
            cache: false,
            views: {
                'menuContent': {
                    templateUrl: 'templates/results.html',
                    controller: 'ResultsCtrl'
                }
            }
        })
        .state('app.resource', {
            url: '/resource/:resourceId',
            views: {
                'menuContent': {
                    templateUrl: 'templates/resource.html',
                    controller: 'ResourceCtrl'
                }
            }
        })
        .state('app.login', {
            url: '/login?fromRegistration',
            cache: false,
            views: {
                'menuContent': {
                    templateUrl: 'templates/login.html',
                    controller: 'LoginCtrl'
                }
            }
        })
        .state('app.account', {
            url: '/account',
            cache: false,
            views: {
                'menuContent': {
                    templateUrl: 'templates/account.html',
                    controller: 'AccountCtrl'
                }
            }
        })
        .state('app.documents', {
            url: '/documents',
            cache: false,
            views: {
                'menuContent': {
                    templateUrl: 'templates/documents.html',
                    controller: 'DocumentsCtrl'
                }
            }
        })
        .state('app.bookmarks', {
            url: '/bookmarks',
            cache: false,
            views: {
                'menuContent': {
                    templateUrl: 'templates/bookmarks.html',
                    controller: 'BookmarksCtrl'
                }
            }
        })
        .state('app.documentsShare', {
			url: '/documents/share/:documentId',
            cache: false,
            views: {
                'menuContent': {
                    templateUrl: 'templates/documents-share.html',
                    controller: 'DocumentsShareCtrl'
                }
            }
        })
        .state('app.bookmarksShare', {
			url: '/bookmarks/share/:bookmarkId',
            cache: false,
            views: {
                'menuContent': {
                    templateUrl: 'templates/bookmarks-share.html',
                    controller: 'BookmarksShareCtrl'
                }
            }
        })
        .state('app.suggest-resource', {
            url: '/suggest-resource',
            cache: false,
            views: {
                'menuContent': {
                    templateUrl: 'templates/suggest-resource.html',
                }
            }
        })
        .state('app.support', {
            url: '/support',
            views: {
                'menuContent': {
                    templateUrl: 'templates/support.html'
                }
            }
        })
        .state('app.dev', {
            url: '/dev',
            views: {
                'menuContent': {
                    templateUrl: 'templates/dev.html',
                    controller: 'DevToolsCtrl'
                }
            }
        });
    // if none of the above states are matched, use this as the fallback
    $urlRouterProvider.otherwise(startUrl);
}])
.config(['AnalyticsProvider', function(AnalyticsProvider){

    GA_LOCAL_STORAGE_KEY = 'ga:clientId';

    //ga_ua = (devMode) ? 'UA-128344856-1': 'UA-39706015-6';

    switch(mode){
        case 'dev':
            //ga_ua = 'UA-128883732-1';
            ga_ua = 'UA-130407935-3';
            break;
        case 'stage':
            ga_ua = 'UA-130407935-1';
            break;
        default:
            switch(clientName) {
                case 'nebraska':
                    //ga_ua = 'UA-39706015-7';
                    ga_ua = 'G-44ZRZJMJ49';
                    break;
                case 'chemung':
                    //ga_ua = 'UA-39706015-2';
                    ga_ua = 'G-44ZRZJMJ49';
                    break;
                case 'rockland':
                    //ga_ua = 'UA-39706015-8';
                    ga_ua = 'G-44ZRZJMJ49';
                    break;
                default:
                    ga_ua = 'G-44ZRZJMJ49';
            }
    }

    AnalyticsProvider.setAccount({
        tracker: ga_ua,
        fields: {
            // storage: 'none',
            // clientId: localStorage.getItem(GA_LOCAL_STORAGE_KEY),
            cookieName: GA_LOCAL_STORAGE_KEY,
            cookieExpires: 200000
        },
        set: {
            checkProtocolTask: null,
            anonymizeIp: true,
            forceSSL: true
        },
        trackPages: true,
        trackEvent: true
    });

    // Track all routes (default is true).
    AnalyticsProvider.trackPages(true);
    AnalyticsProvider.setPageEvent('$stateChangeSuccess');

    // Track all URL query params (default is false).
    AnalyticsProvider.trackUrlParams(true);

    // Ignore first page view (default is false).
    // Helpful when using hashes and whenever your bounce rate looks obscenely low.
    //AnalyticsProvider.ignoreFirstPageLoad(true);

    // Set the domain name
    // AnalyticsProvider.setDomainName(window.location.origin);
    AnalyticsProvider.setDomainName(dynamicRoot);

    // Delay initializing and org account can be created in
    // following run method.
    AnalyticsProvider.delayScriptTag(true);

    // Use analytics.js instead of ga.js
    AnalyticsProvider.useAnalytics(true);

    if(devMode){
        AnalyticsProvider.logAllCalls(true);
        AnalyticsProvider.enterDebugMode(true);
    }
}])
.run(['Analytics', 'Organization', 'DevTools', function(Analytics, Organization, DevTools) {

    DevTools.log.add('Arounja GA UA: ' + ga_ua)

    return Organization.getOrganization().then(function(org){

        if(org){

            Analytics.set('dimension1', org.ID);

            var account
                = JSON.parse(JSON.stringify(Analytics.configuration.accounts[0]));

                account.tracker = org.ga_ua;

                account.name = 'organization';

            Analytics.configuration.accounts.push(account);

            DevTools.log.add('Organization GA UA: ' + org.ga_ua)
        }

        Analytics.createAnalyticsScriptTag();

        return org;
    });
}])
.run(['DevTools', 'Authentication', function(DevTools, Authentication) {

    DevTools.log.add([
        'User Logged In = ' + Authentication.getLoginStatus()
    ])

    if(Authentication.getLoginStatus())    {
        DevTools.log.add([
            'Current Username = ' + Authentication.getUserName()
        ])
    }

}]);

angular.module('template-overrides', []).run(['$templateCache', function($templateCache) {
  $templateCache.put('uib/template/pagination/pagination.html','<li ng-if="::boundaryLinks" ng-class="{disabled: noPrevious()||ngDisabled}" class="page-item"><a href ng-click="selectPage(1, $event)" ng-disabled="noPrevious()||ngDisabled" uib-tabindex-toggle class="page-link">{{::getText(\'first\')}}</a></li>' +
  '<li ng-if="::directionLinks" ng-class="{disabled: noPrevious()||ngDisabled}" class="page-item"><a href ng-click="selectPage(page - 1, $event)" ng-disabled="noPrevious()||ngDisabled" uib-tabindex-toggle class="page-link">{{::getText(\'previous\')}}</a></li>' +
  '<li ng-repeat="page in pages track by $index" ng-class="{active: page.active,disabled: ngDisabled&&!page.active}" class="page-item"><a href ng-click="selectPage(page.number, $event)" ng-disabled="ngDisabled&&!page.active" uib-tabindex-toggle class="page-link">{{page.text}}</a></li>' +
  '<li ng-if="::directionLinks" ng-class="{disabled: noNext()||ngDisabled}" class="page-item"><a href ng-click="selectPage(page + 1, $event)" ng-disabled="noNext()||ngDisabled" uib-tabindex-toggle class="page-link">{{::getText(\'next\')}}</a></li>' +
  '<li ng-if="::boundaryLinks" ng-class="{disabled: noNext()||ngDisabled}" class="page-item"><a href ng-click="selectPage(totalPages, $event)" ng-disabled="noNext()||ngDisabled" uib-tabindex-toggle class="page-link">{{::getText(\'last\')}}</a></li>')
}]);
