angular.module('arounja.controllers').controller('DocumentsCtrl', ['$scope', 'BackendApi', 'restConfig', '$ionicPopup', '$state', '$q', function($scope, BackendApi, restConfig, $ionicPopup, $state, $q) {
    $scope.userDocuments = [];
    $scope.allSelected = {state: false};
	$scope.popupData = {};

    $scope.init = function() {
        $q.all([BackendApi.getDocuments(), BackendApi.getDocumentShares()]).then(function(responses) {
            $scope.userDocuments = responses[0];
            $scope.userSharedDocuments = responses[1];
        });
    };

    $scope.onUploadSuccess = function(response) {
        $scope.userDocuments.push(response.data);
    };

    $scope.downloadDocument = function(doc) {
        BackendApi.downloadDocument(doc.id).then(function(response) {
            window.open(response.uri, '_blank', '');
        });
    };

    $scope.deleteDocument = function(doc) {
        var deletionPopup = $ionicPopup.confirm({
            title: 'Document Deletion',
            template: 'Are you sure you want to delete this document?'
        });

		deletionPopup.then(function(res) {
			if (!res) {
				return;
			}
			BackendApi.deleteDocument(doc.id).then(function(response) {
				$scope.userDocuments = $scope.userDocuments.filter(function(d) {
					return d.id !== doc.id;
				});
			});
		});
    };

    $scope.toggleSelectAll = function() {
        $scope.userDocuments.forEach(function(bookmark) {
            bookmark.selected = $scope.allSelected.state;
        });
    };

    $scope.selectAll = function() {
        $scope.allSelected.state = true;
        $scope.toggleSelectAll();
    };

    $scope.shareSelected = function() {
        if (!$scope.userDocuments.some(function(b) { return b.selected; })) {
            return;
        }
    	var sharePopup = $ionicPopup.show({
            template: '<input type="text" ng-model="popupData.shareEmail">',
			title: 'Enter the email address of the person you would like to share with',
			scope: $scope,
			buttons: [
				{ text: 'Cancel' },
			  	{
				 	text: '<b>Share</b>',
				  	type: 'button-positive',
				  	onTap: function(e) {
					  	if (!$scope.popupData.shareEmail) {
							e.preventDefault();
						} else {
							return $scope.popupData.shareEmail;
						}
					}
				}
			]
		});

		sharePopup.then(function(shareEmail) {
            if (!shareEmail) {
                return;
            }

            var documentIds = $scope.userDocuments.filter(function(ud) {
                return ud.selected
            }).map(function(ud) {
                return parseInt(ud.id)
            });

            BackendApi.addDocumentShares(documentIds, shareEmail).then(function(response) {
                $ionicPopup.show({
                    title: 'Documents Shared',
                    cssClass: 'positive',
                    buttons: [
                        { text: 'Okay' }
                    ]
                });
            }).catch(function(response) {
                $ionicPopup.show({
                    template: '',
                    title: 'This user does not exist',
                    subTitle: 'Send them this link to invite them to join Arounja!<br>https://arounja.org/sign-up/',
                    scope: $scope,
                    cssClass: 'positive',
                    buttons: [
                        { text: 'Okay' }
                    ]
                });
                $scope.popupData.shareEmail = null;
            });
		});
    }

    $scope.shareDocument = function(doc) {
        $state.go('app.documentsShare', {documentId: doc.id})
    };

    $scope.endpoint = restConfig.endpoint;

    $scope.init();
}]);
