angular.module('arounja.controllers').controller('BookmarksShareCtrl', ['$scope', 'BackendApi', 'restConfig', '$ionicPopup', '$stateParams', function($scope, BackendApi, restConfig, $ionicPopup, $stateParams) {
	$scope.userBookmark = {};
    $scope.sharedUser = {email: null};

    $scope.init = function() {
        BackendApi.getBookmark($stateParams.bookmarkId).then(function(response) {
            $scope.userBookmark = response;
        });
    };

    $scope.addShare = function() {
        if ($scope.sharedUser.email === '') {
            $ionicPopup.show({
                template: '',
                title: 'Error',
                subTitle: 'You must enter an email to share a bookmark',
                scope: $scope,
                cssClass: 'positive',
                buttons: [
                    { text: 'Okay' }
                ]
            });
        }
        BackendApi.addBookmarkShares([$stateParams.bookmarkId], $scope.sharedUser.email).then(function(response) {
            $scope.userBookmark.shares.push({
                user_id: response[0],
                user_email: $scope.sharedUser.email
            });
            $scope.sharedUser.email = null;
        }).catch(function(response) {
            $ionicPopup.show({
                template: '',
                title: 'This user does not exist',
                subTitle: 'Send them this link to invite them to join Arounja!<br>https://arounja.org/sign-up/',
                scope: $scope,
                cssClass: 'positive',
                buttons: [
                    { text: 'Okay' }
                ]
            });
            $scope.sharedUser.email = null;
        });
    };

    $scope.deleteShare = function(share) {
        BackendApi.deleteBookmarkShare($stateParams.bookmarkId, share.user_id).then(function(response) {
            $scope.userBookmark.shares = $scope.userBookmark.shares.filter(function(s) {
                return s.id !== share.id;
            });
        });
    }

    $scope.init();
}]);
