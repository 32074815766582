/*global google, angular, _, navigator, cordova*/
/* jshint -W097 */

'use strict';

angular.module('arounja.services').factory('Location', ['$log', '$q', '$http', '$ionicPopup', 'CLIENT_NAME',
  function ($log, $q, $http, $ionicPopup, CLIENT_NAME) {
    var f = {};
    f.savedLocation = {
      'saved': false,
      'coords': {
        'latitude': null,
        'longitude': null
      }
    };

    f.popupOpened = false;

    f.getLocation = function () {
      // if this is a chemung client, center on Elmira
      if (CLIENT_NAME === 'chemung') {
        return $q.when({
          coords: {
            latitude: 42.0898,
            longitude: 76.8077
          }
        });
      }

      var deferred = $q.defer();
      if (f.savedLocation.saved) {
        deferred.resolve(f.savedLocation);
      }

      navigator.geolocation.getCurrentPosition(
        function (position) {
          deferred.resolve(position);
        },
        function (error) {
          f.byIp(deferred);
        }, {
          maximumAge: 3000,
          timeout: 15000,
          enableHighAccuracy: true
        }
      );

      return deferred.promise;
    };

    f.byIp = function (deferred) {
      if (f.savedLocation.saved) {
        deferred.resolve(f.savedLocation);
      } else {
        $http.get('https://ipapi.co/json').then(function (position) {
          f.savedLocation.saved = true;
          f.savedLocation.coords.latitude = position.data.latitude;
          f.savedLocation.coords.longitude = position.data.longitude;
          deferred.resolve({
            'coords': position.data
          });
        });
      }
    };

    f.requestLocationAgain = function (deferred) {
      if (!f.popupOpened) {
        $ionicPopup.show({
          title: 'Location',
          subTitle: 'Arounja needs your location to ensure the most accurate results.  Please allow it in your settings. In the meantime, we\'ll use your approximate location.',
          cssClass: 'positive',
          buttons: [{
            text: '<b>OK</b>',
            type: 'button-positive',
            onTap: function (e) {
              f.byIp(deferred);
            }
          }]
        });
        f.popupOpened = true;
      }
    };

    return f;
  }
]);
