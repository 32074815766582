//hack to show search form after first load, bypassing ionic cache clear on home view load
var searchVisible = false;
angular.module('arounja.controllers').controller('HomeCtrl', ['$scope', '$log', 'SearchCriteria', '$location', 'BackendApi', 'Location', '$state', 'StaticMap', 'airsTopLevel', 'userInfo', '$http', '$q', 'uiGmapGoogleMapApi', 'Geocode', '$timeout', '$ionicHistory', function($scope, $log, SearchCriteria, $location, BackendApi, Location, $state, StaticMap, airsTopLevel, userInfo, $http, $q, uiGmapGoogleMapApi, Geocode, $timeout, $ionicHistory) {
    $ionicHistory.clearHistory();

    $scope.staticMap = null;

    SearchCriteria.setDefaults();

    $scope.searchVisible = searchVisible;

    $scope.go = function() {
        $state.go('app.results');

        searchVisible = false;
    };

    var hostname = window.location.hostname;
    var parts = hostname.split('.');
    var subdomain = parts.length > 2 ? parts[0] : null;

    $scope.isRockland = (subdomain === 'rockland')
  }]);
