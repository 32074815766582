angular.module('arounja.services').factory('Geocode', ['$log', '$q', 'uiGmapGoogleMapApi',
    function($log, $q, uiGmapGoogleMapApi) {
        var f = {};

        f.getCoords = function(location) {
            var deferred = $q.defer();
            uiGmapGoogleMapApi.then(function() {
                var geocoder = new google.maps.Geocoder();
                var coords = {};
                geocoder.geocode({
                        'address': location
                    },
                    function(results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            coords.latitude = results[0].geometry.location.lat();
                            coords.longitude = results[0].geometry.location.lng();
                            coords.formatted_address = results[0].formatted_address;
                            return deferred.resolve(coords);
                        }
                    }
                );
            });

            return deferred.promise;

        };

        // f.getAddress = function(coords) {
        //     var deferred = $q.defer();
        //     var geocoder = new google.maps.Geocoder();
        //     geocoder.geocode({
        //         'location': {
        //             lat: coords.latitude,
        //             lng: coords.longitude
        //         }
        //     }, function(results, status) {
        //         if (status === 'OK') {
        //             if (results[1]) {
        //                 return deferred.resolve(results[1]);
        //             } else {
        //                 window.alert('No results found');
        //             }
        //         } else {
        //             window.alert('Geocoder failed due to: ' + status);
        //         }
        //     });
        //     return deferred.promise;

        // };

        return f;
    }
]);