angular.module('arounja.services').factory('Authentication', ['$log', 'restConfig', '$http', '$q', '$localStorage', '$window', 
    function($log, restConfig, $http, $q, $localStorage, $window) {
        f = {};

        f.loggedIn = false;

        f.storeUserData = function(user) {
            //$localStorage.userName = user.username;
            $window.localStorage.setItem('userName', user.username);
        };

        f.getUserName = function() {
            //return $localStorage.userName;
            return  $window.localStorage.getItem('userName');
        };

        f.getLoginStatus = function() {
            //if ($localStorage.userName && $localStorage.userToken) {
            if($window.localStorage.getItem('userName') && $window.localStorage.getItem('userToken')){
                f.loggedIn = true;
            }
            return f.loggedIn;
        };

        f.login = function(user) {
            var deferred = $q.defer();
            if (!angular.equals(user, {}) && user.username && user.password) {
                $http.post(restConfig.root + 'jwt-auth/v1/token', {
                    username: user.username,
                    password: user.password
                }).then(function(response){
                    //$localStorage.userToken = response.data.token;
                    $window.localStorage.setItem('userToken', response.data.token);
                    //$localStorage.userName = response.data.user_email;
                    $window.localStorage.setItem('userName', response.data.user_email);
                    f.loggedIn = true;
                    deferred.resolve(true);
                }).catch(function(error){
                    deferred.resolve(false);
                });
            }
            return deferred.promise;
        };

        f.logout = function() {
            //$localStorage.$reset();
            $window.localStorage.clear();
            $window.location.reload(true);
        };

        return f;
    }
  ]);
