function randomString(length)
{
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for( var i=0; i < length; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}
function isEmpty(obj) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }

    return JSON.stringify(obj) === JSON.stringify({});
}

function findIndexByKeyValue(obj, key, value)
{
    for (var i = 0; i < obj.length; i++) {
        if (obj[i][key] == value) {
            return i;
        }
    }
    return null;
}

window.addEventListener('keyboardWillShow', function() {
    document.body.classList.add('keyboard--open');
});

window.addEventListener('keyboardWillHide', function() {
    document.body.classList.remove('keyboard--open');
});

window.log = console.log;

// var intervalCount = 0;
// var gaInterval = setInterval(function() {
//     intervalCount++;
//     if(window.ga) {
//         var GA_LOCAL_STORAGE_KEY = 'ga:clientId';
//
//         var arounja_ga = 'UA-39706015-6';
//         if(devMode)
//             arounja_ga = 'UA-128344856-1';
//
//         window.ga('create', arounja_ga, {
//             'storage': 'none',
//             'clientId': localStorage.getItem(GA_LOCAL_STORAGE_KEY)
//         });
//         arounja_ga = undefined;
//      
//         window.ga(function(tracker) {
//             localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
//         });
//      
//         window.ga('set','checkProtocolTask',null);
//         window.ga('set','anonymizeIp',true);
//         window.ga('set','forceSSL',true);
//
//         clearInterval(gaInterval);
//     }
//     if (intervalCount > 50) {
//         clearInterval(gaInterval);
//     }
// }, 100);