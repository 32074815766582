angular.module('arounja.controllers').controller('AccountCtrl', ['$scope', '$log', 'SearchCriteria', '$location', 'BackendApi', 'Location', '$state', 'StaticMap', 'airsTopLevel', 'userInfo', '$http', '$q', 'uiGmapGoogleMapApi', 'Geocode', '$timeout', 'Authentication', '$window', function($scope, $log, SearchCriteria, $location, BackendApi, Location, $state, StaticMap, airsTopLevel, userInfo, $http, $q, uiGmapGoogleMapApi, Geocode, $timeout, Authentication, $window) {
    
    $scope.referrals = [];
    
    $scope.init = function() {
        if ($scope.$parent.isLoggedIn) {
            $scope.userName = Authentication.getUserName();
            BackendApi.getReferrals().then(function(result) {
                $scope.referralsJson = [];
                $scope.referralsLoaded = true;
                for (var key in result) {
                    $scope.referrals.push({
                        'id': key,
                        'title': result[key].resource_title,
                        'referrals': result[key].referral_count
                    });
                }
            });
        }
    };

    $scope.logout = Authentication.logout;

    $scope.goToLogin = function(){
        window.location.href = window.location.origin + '/#/app/login';
    };

    $scope.init();
    
}]);
