angular.module('arounja.controllers').controller('ResultsCtrl', ['$filter', '$rootScope', '$scope', '$log', 'Authentication', 'SearchCriteria', 'BackendApi', '$location', 'Location', '$state', '$ionicModal', '$window', 'userInfo', 'StaticMap', '$ionicHistory', '$ionicTabsDelegate', '$timeout', '$http', '$ionicSlideBoxDelegate', 'uiGmapIsReady', '$interval', 'Analytics', function ($filter, $rootScope, $scope, $log, Authentication, SearchCriteria, BackendApi, $location, Location, $state, $ionicModal, $window, userInfo, StaticMap, $ionicHistory, $ionicTabsDelegate, $timeout, $http, $ionicSlideBoxDelegate, uiGmapIsReady, $interval, Analytics) {
    $scope.initialLoad = false;

    $scope.resultsMap = {
        zoom: 8,
        options: {
            panControl: false,
            mapTypeControl: false,
            overviewMapControl: false,
            streetViewControl: false,
            scrollwheel: true
        },
        markersEvents: {
            click: function (marker, eventName, model, args) {
                $scope.resultsMap.window.model = model;
                $scope.resultsMap.window.show = true;
            }
        },
        window: {
            marker: {},
            show: false,
            closeClick: function () {
                this.show = false;
            },
            options: {} // define when map is ready
        },
        refresh: false,
        control: {}
    };

    $scope.searchCriteria = SearchCriteria;

    $scope.init = function () {
        if (window.location.origin === 'http://127.0.0.1:8100' && devMode) {
            SearchCriteria.mockData();
        }
        document.getElementsByTagName('ion-tabs')[0].classList.remove('tabs-bottom');
        document.getElementsByTagName('ion-tabs')[0].classList.remove('tabs-top');
        $scope.startState();
        $scope.getResources();
    };

    $scope.startState = function () {
        $scope.pagination = {
            numPages: 0,
            foundItems: 0,
            currentPage: 1,
			itemsPerPage: 20,
            orderBy: 'post_title'
        };
        $scope.resources = [];
        $scope.noMoreItemsAvailable = false;
        $scope.numberResourcesRequested = 20;
        $scope.hasResults = true;
        $scope.firstLoadComplete = false;
        $scope.staticMap = null;
        $scope.markerIndex = 1;
        $scope.currentTab = $ionicTabsDelegate.selectedIndex();
        $scope.tempMarkers = [];
        $scope.suggestionsTabOpen = false;
        $scope.showCurrentLocationPin = false;

        $scope.sliderOptions = {
            loop: false,
            effect: 'slide',
            speed: 500,
            pagination: false
        };

        $scope.resultsMap.center = {
            latitude: SearchCriteria.data.latitude,
            longitude: SearchCriteria.data.longitude
        };

        $scope.mapSearchLocation = {
            latitude: SearchCriteria.data.latitude,
            longitude: SearchCriteria.data.longitude
        };
    };

    $scope.pageChange = function() {
        $scope.updateMarkers($scope.resources, ($scope.numberResourcesRequested * $scope.pagination.currentPage) - $scope.numberResourcesRequested + 1);
        $scope.refreshMap();
    }

    $scope.getResources = function () {

        SearchCriteria.trackSearch();

        BackendApi.getResources(SearchCriteria.data).then(function (results) {
            $scope.firstLoadComplete = true;
            if (!results.items.length) {
                $scope.hasResults = false;
                $scope.currentPage = 1;
            }
            if (results.items.length) {
                $scope.cleanSlider();
                $scope.resultsMap.markers = [];
                $scope.updateMarkers(results.items, ($scope.numberResourcesRequested * $scope.pagination.currentPage) - $scope.numberResourcesRequested + 1);
            }
            $scope.resources = results.items;
            $scope.pagination.numPages = results.max_num_pages;
            $scope.pagination.foundItems = results.found_items;
        });
    };

    $scope.updateMarkers = function (results, index) {
        var paginatedResults = $filter('limitTo')(
            $filter('orderBy')(results, $scope.pagination.orderBy),
            $scope.pagination.itemsPerPage,
            $scope.pagination.itemsPerPage * ($scope.pagination.currentPage - 1)
        );
        $scope.tempMarkers = angular.copy(paginatedResults);
        $scope.tempMarkers.map(function (i, x) {
            i.showWindow = false;
            i.options = {};
            i.options.label = {
                text: (index++).toString(),
                color: 'white'
            };
            i.options.icon = {
                url: 'img/marker.png',
                scaledSize: new google.maps.Size(30, 44)
            };
            i.options.zIndex = x;
            return i;
        });
        $scope.tempMarkers[0].options.icon.url = 'img/marker-active.png';
        $scope.tempMarkers[0].options.label.color = 'black';
        $scope.tempMarkers[0].options.zIndex = 100;
        $scope.sliderData = angular.copy($scope.tempMarkers);

        $scope.tempMarkers.unshift({
            location: {
                latitude: SearchCriteria.data.latitude,
                longitude: SearchCriteria.data.longitude
            },
            ID: 'user_location',
            options: {
                icon: {
                    url: 'img/marker-current-location.png',
                    scaledSize: new google.maps.Size(30, 30),
                    anchor: new google.maps.Point(15, 15),
                },
                zIndex: 101,
                label: {
                    text: ' '
                }
            }
        });
    };

    $scope.searchReload = function () {
        $rootScope.$emit('searchReload');
        $state.go($state.current.name, {}, {
            reload: true
        }).then(function () {
            $scope.init();
        });
    };

    $scope.showMapMarker = function (resource) {
        $scope.resultsMap.window.model = resource;
        $scope.resultsMap.window.show = true;
    };

    $scope.setCurrentTab = function () {
        $scope.currentTab = $ionicTabsDelegate.selectedIndex();
        if ($ionicTabsDelegate.selectedIndex() === 1) {
            $scope.refreshMap();
        }
        if ($ionicTabsDelegate.selectedIndex() === 2) {
            $scope.suggestionsTabOpen = true;
        }
    };

    $scope.refreshMap = function () {
        uiGmapIsReady.promise(1).then(function (instances) {
            instances.forEach(function (inst) {
                var interval = $scope.$watch('tempMarkers', function (newMarkers, oldMarkers) {
                    if (newMarkers.length) {
                        $scope.resultsMap.refresh = true;
                        $scope.resultsMap.control.refresh($scope.resultsMap.center);
                        // if (!angular.equals($scope.resultsMap.markers, $scope.tempMarkers)) {
                        //     $scope.resultsMap.markers = $scope.tempMarkers;
                        //     // $scope.selectMapMarker(0, 1);
                        // }
                        $scope.resultsMap.markers = $scope.tempMarkers;
                        if ($scope.resultsMap.zoom < 4) {
                            $scope.resultsMap.zoom = 8;
                        }
                        if ($scope.resultsMap.zoom > 20) {
                            $scope.resultsMap.zoom = 18;
                        }
                        interval();
                    }
                });
                $scope.resultsMap.refresh = true;
                $scope.resultsMap.control.refresh($scope.resultsMap.center);
                $scope.resultsMap.markers = $scope.tempMarkers;
                if ($scope.resultsMap.zoom < 4) {
                    $scope.resultsMap.zoom = 8;
                }
                if ($scope.resultsMap.zoom > 20) {
                    $scope.resultsMap.zoom = 18;
                }
            });
        });
    };

    $scope.slideDirection = function (direction) {
        return direction === 'next' ? $scope.slider.slideNext() : $scope.slider.slidePrev();
    };

    $scope.$on("$ionicSlides.sliderInitialized", function (event, data) {
        $scope.slider = data.slider;
    });

    $scope.$on("$ionicSlides.slideChangeStart", function (event, data) {
        if ($scope.slider.slides.length) {
            $scope.selectMapMarker(data.slider.activeIndex, data.slider.previousIndex);
        }
    });

    $scope.cleanSlider = function () {
        if ($scope.slider) {
            $scope.slider.removeAllSlides();
        }
    };

    $scope.selectMapMarker = function (index, prev) {
        if (SearchCriteria.data.locationString === 'Current Location') {
            index++;
            prev++;
        }
        var current = $scope.resultsMap.markers[index];
        var last = $scope.resultsMap.markers[prev];
        if (typeof prev !== 'undefined' && last.ID !== 'user_location') {
            last.options.icon.url = 'img/marker.png';
            last.options.label.color = 'white';
            last.options.zIndex = parseInt(last.options.label.text) - 1;
        }
        current.options.icon.url = 'img/marker-active.png';
        current.options.label.color = 'black';
        current.options.zIndex = 100;
        $scope.safeApply();
    };

    $scope.slideTo = function (marker) {
        var index = parseInt(marker.label.text) - 1;
        $scope.slider.slideTo(index);
    };

    $scope.$on('$ionicView.beforeLeave', function (event, data) {
        if ($state.current.name === 'app.home') {
            SearchCriteria.setDefaults();
        }
    });

    $scope.safeApply = function (fn) {
        var phase = this.$root.$$phase;
        if (phase == '$apply' || phase == '$digest') {
            if (fn && (typeof (fn) === 'function')) {
                fn();
            }
        }
        else {
            this.$apply(fn);
        }
    };

    $scope.init();

}]);
