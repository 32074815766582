angular.module('arounja.controllers').controller('LoginCtrl', ['$scope', '$ionicModal', '$timeout', '$log', 'Authentication', '$http', 'restConfig', '$state', '$ionicHistory', '$window', '$localStorage', 'Analytics', '$stateParams', function($scope, $ionicModal, $timeout, $log, Authentication, $http, restConfig, $state, $ionicHistory, $window, $localStorage, Analytics, $stateParams) {
    $scope.loginData = {};
    $scope.checkingLogin = false;
    $scope.fromRegistration = $stateParams.fromRegistration === 'true';
    $scope.error = false;
    if (devMode) {
        //$scope.loginData.username = 'test@test.com';
        //$scope.loginData.password = 'test2';
    }
    if (Authentication.getLoginStatus()) {
        $state.go('app.home');
    }
    $scope.doLogin = function() {
        $scope.checkingLogin = true;
        Authentication.login($scope.loginData).then(function(response) {
            if (response) {
                $scope.$parent.isLoggedIn = true;
                $scope.error = false;
                $scope.checkingLogin = false;
                Analytics.trackEvent('Accounts', 'Login', 'Success');
                $window.location.reload(true);
            }
            else {
                $scope.error = true;
                $scope.checkingLogin = false;
                $scope.$parent.isLoggedIn = false;
                Analytics.trackEvent('Accounts', 'Login', 'Failure');
            }
        });
    };
  }]);
