angular.module('arounja.services').factory('BackendApi', ['$log', '$q', '$http', '$httpParamSerializerJQLike', 'restConfig', 'Authentication', '$ionicPopup', '$window', 'Cookies', 'Analytics', 'Airs', 'DevTools', function($log, $q, $http, $httpParamSerializerJQLike, restConfig, Authentication, $ionicPopup, $window, Cookies, Analytics, Airs, DevTools) {

    var f = {};

    f.popup = {
        instance: $ionicPopup,
        visible: false
    };

    f.queryString = function(params) {
        return $httpParamSerializerJQLike(params);
    };

    f.getOrganization = function(){
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/organization';
        return f.makeRequest(url, 'GET')
        .then(function(result){
            if(result)
                DevTools.log.add(["BackendApi Organization:", result]);
            return result;
        });
    };

    f.getUsers = function(query) {
        var deferred = $q.defer();
        var url = 'http://arounja.today/wp-json/wp/v2/users?search=' + query;
        f.makeRequest(url, 'GET').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.addBookmark = function(resourceId) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/bookmarks/';
        f.makeRequest(url, 'POST', JSON.stringify({
            resourceId: resourceId
        })).then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    }

    f.getBookmarks = function() {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/bookmarks/';
        f.makeRequest(url, 'GET').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.getBookmark = function(bookmarkId) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/bookmarks/' + bookmarkId;
        f.makeRequest(url, 'GET').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.getBookmarkShares = function() {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/bookmarks/share';
        f.makeRequest(url, 'GET').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    }

    f.addBookmarkShare = function(bookmarkId, userId) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/bookmarks/' + bookmarkId + '/share/' + userId;
        f.makeRequest(url, 'POST').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    }

    f.addBookmarkShares = function(bookmarkIds, sharedUserEmail) {
        var url = restConfig.endpoint + '/bookmarks/share/';
        var shareObject = {
            email: sharedUserEmail,
            bookmarkIds: bookmarkIds
        };
        return f.makeRequest(url, 'POST', JSON.stringify(shareObject), true);
    }

    f.deleteBookmarkShare = function(bookmarkId, userId) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/bookmarks/' + bookmarkId + '/share/' + userId;
        f.makeRequest(url, 'DELETE').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    }

    f.deleteBookmark = function(bookmarkId) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/bookmarks/' + bookmarkId;
        f.makeRequest(url, 'DELETE').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.getDocumentShares = function() {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/documents/share';
        f.makeRequest(url, 'GET').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    }

    f.addDocumentShare = function(documentId, userId) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/documents/' + documentId + '/share/' + userId;
        f.makeRequest(url, 'POST').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    }

    f.addDocumentShares = function(documentIds, sharedUserEmail) {
        var url = restConfig.endpoint + '/documents/share/';
        var shareObject = {
            email: sharedUserEmail,
            documentIds: documentIds
        };
        return f.makeRequest(url, 'POST', JSON.stringify(shareObject), true);
    }

    f.deleteDocumentShare = function(documentId, userId) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/documents/' + documentId + '/share/' + userId;
        f.makeRequest(url, 'DELETE').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    }

    f.getDocument = function(documentId) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/documents/' + documentId;
        f.makeRequest(url, 'GET').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.getDocuments = function() {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/documents/';
        f.makeRequest(url, 'GET').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.downloadDocument = function(documentId) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/documents/' + documentId + '/download';
        f.makeRequest(url, 'GET').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.deleteDocument = function(documentId) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/documents/' + documentId;
        f.makeRequest(url, 'DELETE').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.getResources = function(SearchCriteria, requestedReturn) {
        var deferred = $q.defer();
        page = typeof page !== 'undefined' ? page : 0;
        num = typeof num !== 'undefined' ? num : 10;
        requestedReturn = typeof requestedReturn !== 'undefined' ? requestedReturn : '';
        var query = this.queryString(SearchCriteria);
        var url = restConfig.endpoint + '/resources?' + query + '&return=' + requestedReturn;
        f.makeRequest(url, 'GET').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.getHighlightedAirsCategory = function() {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/airs/highlighted';
        f.makeRequest(url, 'GET').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    }

    f.getResource = function(id, SearchCriteria) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/resources/' + id;
        var ratingUrl = restConfig.endpoint + '/resources/' + id + '/rating';
		var checkinUrl = restConfig.endpoint + '/resources/' + id + '/checkin';
        $q.all([f.makeRequest(url, 'GET'), f.makeRequest(ratingUrl, 'GET'), f.makeRequest(checkinUrl, 'GET')]).then(function(results) {
			results[0].rating = results[1].rating;
			results[0].checkins = results[2];
            return deferred.resolve(results[0]);
        });
        return deferred.promise;
    };

    f.submitCheckin = function(resourceId) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/resources/' + resourceId + '/checkin';
        f.makeRequest(url, 'POST').then(function(results) {
            return deferred.resolve(results);
        });
        return deferred.promise;
    };

    f.submitRating = function(resourceId, rating) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/resources/' + resourceId + '/rating?rating=' + rating;
        f.makeRequest(url, 'POST').then(function(results) {
            return deferred.resolve(results);
        });
        return deferred.promise;
    };

    f.getAirs = function(parent, includeChildren, hideEmpty, keyword, orderby) {
        var deferred = $q.defer();
        includeChildren = typeof includeChildren === 'undefined' ? false : true;
        hideEmpty = typeof hideEmpty === 'undefined' ? true : false;
        orderby = typeof orderby === 'undefined' ? '' : orderby;
        var query = {
            'parent': parent,
            'include_children': includeChildren,
            'hide_empty': hideEmpty,
            'keyword': keyword,
            'orderby': orderby
        };
        var url = restConfig.endpoint + '/airs?' + this.queryString(query);
        f.makeRequest(url, 'GET').then(function(result) {

            // Add airs to local memory
            for(var i in result){
                var exists = Airs.find(function(air){
                    return air.term_id === result[i].term_id;
                });

                if(!exists){
                    Airs.push(result[i]);
                }
            }

            return deferred.resolve(result);
        });

        return deferred.promise;
    };

    f.getAir = function(id) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/airs/' + id;
        f.makeRequest(url, 'GET').then(function(result) {

            // Add Air to local memory
            var exists = Airs.find(function(air){
                return air.term_id === result.term_id;
            });

            if(!exists){
                Airs.push(result);
            }

            return deferred.resolve(result);
        });

        return deferred.promise;
    };

    f.modifyResource = function(params, id) {
        var deferred = $q.defer();
        id = typeof id === 'undefined' ? '?' : '/' + id + '?';
        var url = restConfig.endpoint + '/resources' + id + this.queryString(params);
        f.makeRequest(url, 'POST').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.getReferrals = function() {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/referrals';
        f.makeRequest(url, 'GET').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.updateReferralCount = function(id) {
        var deferred = $q.defer();
        var url = restConfig.endpoint + '/referrals/' + id;
        f.makeRequest(url, 'POST').then(function(result) {
            return deferred.resolve(result);
        });
        return deferred.promise;
    };

    f.makeRequest = function(url, method, data, allowRejection) {
        var deferred = $q.defer();

        $http({
            method: method,
            url: url,
            data: data
        }).then(function(result) {
            if(result)
                DevTools.log.add('BackendApi Response:', method + ' ' + url, result)
            deferred.resolve(result.data);
        }, function(error) {
            DevTools.log.add(['BackendApi Error:', method + ' ' + url, error]);
            if (!allowRejection) {
                f.showError(error);
            }
            deferred.reject(error);
        });

        DevTools.log.add(['BackendApi Request:', method + ' ' + url]);

        return deferred.promise;
    };

    f.showError = function(error) {
        if (!f.popup.visible) {
            var supportEmail = 'mailto:support@arounja.org?subject=Arounja%20Error%20Report&body=Message:%20' + encodeURIComponent(JSON.stringify(error.data)) +
            '%0D%0AError%20Status%20Code:%20' + error.status +
            '%0D%0AUser%20Logged%20In:%20' + Authentication.getLoginStatus() +
            '%0D%0ARequest%20URL:%20' + encodeURIComponent(error.config.url) +
            '%0D%0ARequest%20Token:%20' + error.config.headers.Authorization;
            '%0D%0ARequest%20Method:%20' + error.config.method;
            if (error.data && error.data.code === 'no_license') {
                Analytics.trackEvent('Accounts', 'Get License', 'License Not Found');

                f.popup.instance.show({
                    title: 'License not found',
                    cssClass: 'positive',
                    subTitle: 'It looks like you did not activate your Arounja license.',
                    buttons: [
                        {
                            text: '<b>Contact Support</b>',
                            type: '',
                            onTap: function(e) {
                                f.popup.visible = false;
                                window.location.href = supportEmail;
                            }
                        }, {
                            text: '<b>Activate License</b>',
                            type: 'button-positive',
                            onTap: function(e) {
                                f.popup.visible = false;
                                var url = 'http://arounja.org/my-account';
                                window.location.href = url;
                            }
                        }
                    ]
                });
            } else if(error.status === 403) {
                Analytics.trackEvent('Accounts', 'Authentication', 'Authentication Error')
                f.popup.instance.show({
                    title: 'Authentication Error',
                    cssClass: 'positive',
                    subTitle: 'Something went wrong while trying to authenticate. Please logout and login again. If this error persists, contact support.',
                    buttons: [
                        {
                            text: '<b>Contact Support</b>',
                            type: '',
                            onTap: function(e) {
                                f.popup.visible = false;
                                window.location.href = supportEmail;
                            }
                        }, {
                            text: '<b>Logout</b>',
                            type: 'button-positive',
                            onTap: function(e) {
                                Authentication.logout();
                            }
                        }
                    ]
                });
            } else {
                f.popup.instance.show({
                    title: 'Error',
                    cssClass: 'positive',
                    subTitle: 'We\'ve encountered an error while accessing Arounja data.  Please wait while our engineers are working on the problem.  Please contact us if the problem persists',
                    buttons: [
                        {
                            text: '<b>Contact Support</b>',
                            type: '',
                            onTap: function(e) {
                                f.popup.visible = false;
                                window.location.href = supportEmail;
                            }
                        }, {
                            text: '<b>OK</b>',
                            type: 'button-positive',
                            onTap: function(e) {
                                f.popup.visible = false;
                            }
                        }
                    ]
                });
            }

            //DevTools.log.add(['BackendApi Error Support Email:', supportEmail]);
        }

        f.popup.visible = true;
    };

    return f;
}]);
