/* eslint-disable no-undef */
angular.module('arounja.services').factory('PlacesAutocomplete', ['$log', '$q', 'uiGmapGoogleMapApi',
  function ($log, $q, uiGmapGoogleMapApi) {
    var f = {};

    f.getPlace = function (val, types) {
      var deferred = $q.defer();
      var options = {
        input: val,
        language: 'en',
        componentRestrictions: { country: 'us' },
      };
      if (typeof types !== 'undefined') {
        options.types = [types];
      }

      uiGmapGoogleMapApi.then(function () {
        autoCompleteService = new google.maps.places.AutocompleteService();
        autoCompleteService.getPlacePredictions(options, function (predictions, status) {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            deferred.resolve(false);
          }
          deferred.resolve(predictions);
        });
      });
      return deferred.promise;
    };

    return f;
  },
]);
