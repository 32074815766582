function onDeviceReady() {
    cordova.plugins.diagnostic.requestLocationAuthorization(function(status) {
        console.log("Successfully requested location authorization: authorization was " + status);
    }, function(error) {
        console.error(error);
    });
}

$(document).on("deviceready", onDeviceReady);

// window.addEventListener('native.keyboardshow', keyboardShowHandler);

// function keyboardShowHandler(e){
//     console.log('Keyboard height is: ' + e.keyboardHeight);
//     $('body').addClass('')
// }