angular.module('arounja.directives').directive('searchFilter', function() {
    return {
        templateUrl: 'templates/directives/filter.html',
        restrict: 'E',
        replace: true,
		scope: {
			buttonActivator: '=buttonactivator',
		},
        controller: ['$scope', '$log', 'SearchCriteria', '$location', 'BackendApi', 'Location', '$state', 'airsTopLevel', 'userInfo', '$http', '$q', 'uiGmapGoogleMapApi', 'Geocode', '$timeout', 'PlacesAutocomplete', '$ionicModal', '$rootScope', function($scope, $log, SearchCriteria, $location, BackendApi, Location, $state, airsTopLevel, userInfo, $http, $q, uiGmapGoogleMapApi, Geocode, $timeout, PlacesAutocomplete, $ionicModal, $rootScope) {
            $scope.selectedAirs = null;
            $scope.airs = [];
            $scope.selectedAirsDetailed = [];
            $scope.description = null;
            $scope.previous = [0];
            $scope.showBackButton = false;
            $scope.currentAir = null;
            $scope.level = 0;
            $scope.loadingAirTerm = false;

            $scope.setupSelectedAirs = function () {
                $scope.selectedAirsDetailed = [];
                $scope.selectedAirs = angular.copy(SearchCriteria.data.airs);
                angular.forEach($scope.selectedAirs, function(id) {
                    BackendApi.getAir(id).then(function(air) {
                        $scope.selectedAirsDetailed.push(air);
                        $scope.loadingAirTerm = false;
                    });
                });
            }

            $scope.getAirs = function(parent) {
                BackendApi.getAirs(parent, true, false, '').then(function(result) {
                    $scope.airs = result;
                    $scope.showBackButton = $scope.previous.length > 1 ? true : false;
                    $scope.currentAir = $scope.previous[$scope.previous.length - 1];
                });
            };

            $scope.getChildren = function(parent) {
                $scope.previous.push(parent);
                $scope.getAirs(parent.term_id);
                $scope.level++;
            };

            $scope.getParent = function() {
                $scope.previous.pop();
                $scope.level--;
                if (!$scope.previous[$scope.previous.length - 1]) {
                    $scope.getAirs(0);
                } else {
                    $scope.getAirs($scope.previous[$scope.previous.length - 1].term_id);
                }
            };

            $scope.toggleSelected = function(id) {
                var selected = $scope.selectedAirs.indexOf(id);
                //don't do anything while loading airterm to prevent double click
                if($scope.loadingAirTerm) {
                    return;
                }
                if (selected > -1) {
                    $scope.selectedAirs.splice(selected, 1);
                    angular.forEach($scope.selectedAirsDetailed, function(val, index) {
                        if(val.term_id === id) {
                            $scope.selectedAirsDetailed.splice(index, 1);
                        }
                    });
                    $scope.selectedAirsDetailed.splice();
                } else if (!$scope.loadingAirTerm) {
                    $scope.loadingAirTerm = true;
                    $scope.selectedAirs.push(id);
                    BackendApi.getAir(id).then(function(air) {
                        $scope.selectedAirsDetailed.push(air);
                        $scope.loadingAirTerm = false;
                    });
                }
            };

            $scope.isSelected = function(id) {
                return $scope.selectedAirs.indexOf(id) > -1 ? true : false;
            };

            $scope.applyFilter = function() {
                SearchCriteria.data.airs = $scope.selectedAirs;
                SearchCriteria.data.airsDetailed = $scope.selectedAirsDetailed;
                SearchCriteria.trackSearch();

                $scope.modal.hide();
                $scope.$parent.search();
            };

            $scope.modalCancel = function() {
                $scope.setupSelectedAirs();
                $scope.modal.hide();
            }

            $scope.filterTopLevelAirs = function(air) {
                return [
                    'Basic Needs', 'Consumer Services', 'Criminal Justice and Legal Services',
                    'Education', 'Environment and Public Health/Safety', 'Health Care',
                    'Income Support and Employment', 'Individual and Family Life',
                    'Mental Health and Substance Use Disorder Services',
                    'Organizational/Community/International Services',
                    'Target Populations'
                ].indexOf(air.name) !== -1
            };

            $ionicModal.fromTemplateUrl('templates/filter-modal.html', {
                scope: $scope,
                animation: 'slide-in-up'
            }).then(function(modal) {
                $scope.modal = modal;
                $scope.init();
            });

            $rootScope.$on('searchReload', function () {
                $scope.init();
            });

            $scope.init = function() {
                $scope.getAirs(0);
                $scope.setupSelectedAirs();
            }

            $scope.$on('$destroy', function() {
                $scope.modal.remove();
            });
        }]
    };
});
