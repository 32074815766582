angular.module('arounja.controllers').controller('BookmarksCtrl', ['$scope', 'BackendApi', 'restConfig', '$ionicPopup', '$state', '$q', function($scope, BackendApi, restConfig, $ionicPopup, $state, $q) {
    $scope.userBookmarks = [];
    $scope.allSelected = {state: false};
	$scope.popupData = {};

    $scope.init = function() {
        $q.all([BackendApi.getBookmarks(), BackendApi.getBookmarkShares()]).then(function(responses) {
            $scope.userBookmarks = responses[0];
            $scope.userSharedBookmarks = responses[1];
        });
    };

    $scope.deleteBookmark = function(bookmark) {
        var deletionPopup = $ionicPopup.confirm({
            title: 'Bookmark Deletion',
            template: 'Are you sure you want to delete this bookmark?'
        });

		deletionPopup.then(function(res) {
			if (!res) {
				return;
			}
			BackendApi.deleteBookmark(bookmark.id).then(function(response) {
				$scope.userBookmarks = $scope.userBookmarks.filter(function(d) {
					return d.id !== bookmark.id;
				});
			});
		});
    };

    $scope.toggleSelectAll = function() {
        $scope.userBookmarks.forEach(function(bookmark) {
            bookmark.selected = $scope.allSelected.state;
        });
    };

    $scope.selectAll = function() {
        $scope.allSelected.state = true;
        $scope.toggleSelectAll();
    };

    $scope.shareSelected = function() {
        if (!$scope.userBookmarks.some(function(b) { return b.selected; })) {
            return;
        }
    	var sharePopup = $ionicPopup.show({
            template: '<input type="text" ng-model="popupData.shareEmail">',
			title: 'Enter the email address of the person you would like to share with',
			scope: $scope,
			buttons: [
				{ text: 'Cancel' },
			  	{
				 	text: '<b>Share</b>',
				  	type: 'button-positive',
				  	onTap: function(e) {
					  	if (!$scope.popupData.shareEmail) {
							e.preventDefault();
						} else {
							return $scope.popupData.shareEmail;
						}
					}
				}
			]
		});

		sharePopup.then(function(shareEmail) {
            if (!shareEmail) {
                return;
            }

            var bookmarkIds = $scope.userBookmarks.filter(function(ub) {
                return ub.selected
            }).map(function(ub) {
                return parseInt(ub.id);
            });

            BackendApi.addBookmarkShares(bookmarkIds, shareEmail).then(function(response) {
                $ionicPopup.show({
                    title: 'Bookmarks Shared',
                    cssClass: 'positive',
                    buttons: [
                        { text: 'Okay' }
                    ]
                });
            }).catch(function(response) {
                $ionicPopup.show({
                    template: '',
                    title: 'This user does not exist',
                    subTitle: 'Send them this link to invite them to join Arounja!<br>https://arounja.org/sign-up/',
                    scope: $scope,
                    cssClass: 'positive',
                    buttons: [
                        { text: 'Okay' }
                    ]
                });
                $scope.popupData.shareEmail = null;
            });
		});
    }

    $scope.shareBookmark = function(bookmark) {
        $state.go('app.bookmarksShare', {bookmarkId: bookmark.id})
    };

    $scope.init();
}]);
