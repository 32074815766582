angular.module('arounja.controllers').controller('DocumentsShareCtrl', ['$scope', 'BackendApi', 'restConfig', '$ionicPopup', '$stateParams', function($scope, BackendApi, restConfig, $ionicPopup, $stateParams) {
	$scope.userDocument = {};
    $scope.sharedUser = {email: null};

    $scope.init = function() {
        BackendApi.getDocument($stateParams.documentId).then(function(response) {
            $scope.userDocument = response;
        });
    };

    $scope.getUsers = function(query) {
        return BackendApi.getUsers(query)
    };

    $scope.addShare = function() {
        if ($scope.sharedUser.email === '') {
            $ionicPopup.show({
                template: '',
                title: 'Error',
                subTitle: 'You must enter an email to share a document',
                scope: $scope,
                cssClass: 'positive',
                buttons: [
                    { text: 'Okay' }
                ]
            });
        }
        BackendApi.addDocumentShares([$stateParams.documentId], $scope.sharedUser.email).then(function(response) {
            $scope.userDocument.shares.push({
                user_id: response[0],
                user_email: $scope.sharedUser.email
            });
            $scope.sharedUser.email = null;
        }).catch(function(response) {
            $ionicPopup.show({
                template: '',
                title: 'This user does not exist',
                subTitle: 'Send them this link to invite them to join Arounja!<br>https://arounja.org/sign-up/',
                scope: $scope,
                cssClass: 'positive',
                buttons: [
                    { text: 'Okay' }
                ]
            });
            $scope.sharedUser.email = null;
        });
    };

    $scope.deleteShare = function(share) {
        BackendApi.deleteDocumentShare($stateParams.documentId, share.user_id).then(function(response) {
            $scope.userDocument.shares = $scope.userDocument.shares.filter(function(s) {
                return s.id !== share.id;
            });
        });
    }

    $scope.init();
}]);
