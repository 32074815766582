angular.module('arounja.filters', []).filter('getLatestCheckin', function() {
	return function(input) {
		var d = new Date(input.sort(function(a, b) {
			return new Date(b.time) - new Date(a.time);
		})[0].time);

		var timeOfDay = Math.floor(d.getHours() / 12) === 1 ? 'pm' : 'am'
		var hour = d.getHours() / 12 === 0 ? 12 : d.getHours() % 12;
		var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();

		return (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear() + ' at ' + hour + ':' + minutes + ' ' + timeOfDay;
	};
})
