angular.module('arounja.services').factory('StaticMap', ['$log', '$q', 'Location', '$window',
    function($log, $q, Location, $window) {
        var f = {};
        f.getStaticMap = function(location, zoom, size) {
            var deferred = $q.defer();
            if (typeof size === 'undefined') {
                size = {
                    height: Math.ceil($window.innerHeight),
                    width: Math.ceil($window.innerWidth)
                };
            }
            else{
                size.height = Math.ceil(size.height);
                size.width = Math.ceil(size.width);
            }
            var mapsOptions = '&size=' + size.width + 'x' + size.height + '&scale=1';
            if (!zoom) {
                zoom = 5;
            }

            zoom = '&zoom=' + String(zoom);

            var staticMap = 'https://maps.googleapis.com/maps/api/staticmap?center=' + location.latitude + ',' + location.longitude + zoom + mapsOptions + '&key=AIzaSyCdOqYUvzL2lI5aJ43SYBJYYl3fkbtounE';

            f.map = staticMap;

            deferred.resolve(staticMap);

            return deferred.promise;
        };

        return f;
    }
]);
