angular.module('arounja.services').factory('Organization', ['$log', '$q', 'Cookies', 'BackendApi', 'Analytics', function($log, $q, Cookies, BackendApi, Analytics) {
        var f = window.f = {};

        f.current = null;
        
        f.getOrganization = function () {
            
            return BackendApi.getOrganization();

        }

        return f;
    }
]);