angular.module('arounja.controllers').controller('DevToolsCtrl', ['$scope', '$log', 'SearchCriteria', '$location', 'BackendApi', 'Location', '$state', 'StaticMap', 'airsTopLevel', 'userInfo', '$http', '$q', 'uiGmapGoogleMapApi', 'Geocode', '$timeout', '$ionicHistory', 'DevTools', function($scope, $log, SearchCriteria, $location, BackendApi, Location, $state, StaticMap, airsTopLevel, userInfo, $http, $q, uiGmapGoogleMapApi, Geocode, $timeout, $ionicHistory, DevTools) {
    window.$scope = $scope;
    $scope.tab = 'log';
    $scope.cmd = "";
    $scope.BackendApi = BackendApi;
    $scope.eval = function(e, i){
        if(e.key === "Enter"){
            var result = $scope.$eval(e.target.value);
            
            DevTools.log.add(['> ' + e.target.value, result]);

            e.target.value = '';
        }
    }
    $scope.test = function(){
        alert('test');
    }
}]);