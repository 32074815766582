angular.module('arounja.directives').directive('createModifyResource', function() {
    return {
        templateUrl: 'templates/directives/create-modify-resource.html',
        restrict: 'E',
        replace: true,
        scope: {
            type: '=',
            resource: '='
        },
        controller: ['$scope', '$log', 'SearchCriteria', 'BackendApi', 'Location', '$state', 'airsTopLevel', 'userInfo', '$http', '$q', 'uiGmapGoogleMapApi', 'Geocode', '$timeout', 'PlacesAutocomplete', '$filter', '$interval', '$ionicPopup', '$ionicTabsDelegate', '$window', 'Analytics', function($scope, $log, SearchCriteria, BackendApi, Location, $state, airsTopLevel, userInfo, $http, $q, uiGmapGoogleMapApi, Geocode, $timeout, PlacesAutocomplete, $filter, $interval, $ionicPopup, $ionicTabsDelegate, $window, Analytics) {
            var tabsHandle = null;

            $scope.startState = function() {
                if (typeof $scope.userInput === 'undefined') {
                    $scope.userInput = {};
                }

                $scope.suggestions = {
                    airs: {
                        appendTo: $('.airs-suggestions'),
                        selected: []
                    }
                };
                $scope.randString = randomString(5);
                $scope.typeaheadAppend = {
                    address: $('.address-suggestions')
                };
                $scope.formSubmitted = false;
                if ($scope.type === 'Update') {
                    $scope.header = 'Update ' + $scope.resource.post_title;
                    tabsHandle = 'resource-tabs';
                } else {
                    $scope.header = 'Create new resource';
                    tabsHandle = 'results-tabs';
                }
                $scope.userInput.post_title = '';
                $scope.userInput.markdown = '';
                $scope.userInput.latitude = '';
                $scope.userInput.longitude = '';
                $scope.userInput.website = '';
                $scope.userInput.phone = '';
                $scope.userInput.address = '';
                $scope.userInput.email = '';
                $scope.userInput.airs = '';
                $scope.suggestions.airs.selected = [];
                $scope.validator = {
                    phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                };
            };

            $scope.init = function() {
                $scope.startState();
                if ($scope.resource) {
                    angular.element(document).ready(function() {
                        $scope.resource.address = $scope.resource.location.address;
                        $scope.suggestions.airs.selected = $scope.resource.airs;
                        $scope.resource.latitude = $scope.resource.location.latitude;
                        $scope.resource.longitude = $scope.resource.location.longitude;
                        angular.merge($scope.userInput, $scope.resource);
                        $scope.userInput.airs = '';
                    });
                }
            };

            $scope.sendResource = function() {
                $scope.validation();
                if (!$scope.userInput.$invalid) {
                    var airs = $scope.suggestions.airs.selected.map(function(i) {
                        return i.term_id || i.id;
                    }).join();
                    var params = {
                        'post_title': $scope.userInput.post_title,
                        'post_content': $scope.userInput.markdown,
                        'latitude': $scope.userInput.latitude,
                        'longitude': $scope.userInput.longitude,
                        'address': $scope.userInput.address,
                        'phone': $scope.userInput.phone,
                        'email': $scope.userInput.email,
                        'airs': airs,
                        'website': $scope.userInput.website,
                    };
                    var id = $scope.resource ? $scope.resource.ID : undefined;
                    BackendApi.modifyResource(params, id).then(function(result) {
                        $scope.confirmCompletion(result);
                        //ga.event('resource', 'suggest', 'submit', params.post_title)
                    });
                }
            };

            $scope.confirmCompletion = function(result) {

                if ($scope.type === 'Update' && result === 'ok') {
                    Analytics.trackEvent('Resources', 'Updated', $scope.userInput.post_title)

                    var updatePopup = $ionicPopup.show({
                        template: '',
                        title: 'Resource updated',
                        subTitle: 'Thanks for submitting an update to this resource.  Your suggestion will be reviewed and published if approved.',
                        scope: $scope,
                        cssClass: 'positive',
                        buttons: [
                            {
                                text: '<b>Done</b>',
                                type: 'button-positive',
                                onTap: function(e) {
                                    $ionicTabsDelegate.$getByHandle(tabsHandle).select(0);
                                }
                            }
                        ]
                    });
                }

                if ($scope.type === 'Suggest' && result === 'ok') {
                    Analytics.trackEvent('Resources', 'Submitted', $scope.userInput.post_title)

                    var createPopup = $ionicPopup.show({
                        template: '',
                        title: 'Resource saved',
                        subTitle: 'Thanks for submitting a new resource.  Your suggestion will be reviewed and published if approved.',
                        scope: $scope,
                        cssClass: 'positive',
                        buttons: [
                            {
                                text: 'Suggest Another',
                                onTap: function(e) {
                                    Analytics.trackEvent('Buttons', 'Clicked', 'Suggest Another Resource');
                                    $scope.startState();
                                }
                            }, {
                                text: '<b>Done</b>',
                                type: 'button-positive',
                                onTap: function(e) {
                                    Analytics.trackEvent('Buttons', 'Clicked', 'Done Saving Resource')
                                    $ionicTabsDelegate.$getByHandle(tabsHandle).select(0);
                                    $scope.startState();
                                }
                            }
                        ]
                    });
                }
            };

            $scope.validation = function() {
                $scope.formSubmitted = true;
            };

            $scope.getLocation = function(val) {
                var deferred = $q.defer();
                PlacesAutocomplete.getPlace(val, 'address').then(function(results) {
                    deferred.resolve(results);
                });
                return deferred.promise;
            };

            $scope.setLocationQuery = function(location) {
                $scope.userInput.address = location;
                Geocode.getCoords(location).then(function(result) {
                    $scope.userInput.latitude = result.latitude;
                    $scope.userInput.longitude = result.longitude;
                });
            };

            $scope.getAirsSuggestions = function(keyword) {
                var deferred = $q.defer();
                BackendApi.getAirs(-1, true, false, keyword).then(function(results) {
                    deferred.resolve(results);
                });
                return deferred.promise;
            };

            $scope.airsSelect = function(air) {
                $scope.suggestions.airs.selected.push(air);
            };

            $scope.airsDeselect = function(air) {
                $scope.suggestions.airs.selected.splice(findIndexByKeyValue($scope.suggestions.airs.selected, 'term_id', air.term_id), 1);
            };

            $scope.open = function(url) {
                if (!angular.equals(ionic.Platform.device(), {})) {
                    $window.open(url, '_system');
                } else {
                    $window.open(url, '_blank');
                }
            };

            $scope.init();
        }]
    };
});
