angular.module('arounja.services').factory('DevTools', ['$log', '$q', 'Cookies', function($log, $q, Cookies) {
        var dt = {};

        dt.mode = mode;
        dt.devMode = devMode;
        dt.dynamicRoot = dynamicRoot;

        dt.log = [];

        dt.log.add = function(){
            if(!dt.devMode)
                return;

            function log(item){
                if(item instanceof Object){
                    dt.log.push(JSON.stringify(item))
                } else {
                    dt.log.push(item);
                }
            }

            log('<b>' + new Date(Date.now()).toLocaleString() + '</b>');

            if(arguments[0] instanceof Array){
                for(var i = 0; i < arguments[0].length; i++){
                    log(arguments[0][i]);
                }
            } else {
                log(arguments[0]);
            }

            log("&nbsp;");
        }

        dt.log.add([
            'DevTools Initialized', 
            'origin = ' + window.location.origin, 
            'mode = ' + dt.mode, 
            "devMode = " + dt.devMode.toString(), 
            'dynamicRoot = ' + dt.dynamicRoot,
        ]);

        (function(log) {
            console.log = function(){
                log.apply(console, arguments);
                angular.element(document.body).scope().$apply(function(){
                    dt.log.add(arguments);
                })
            }
        })(console.log);

        return dt;
    }
]);