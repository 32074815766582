angular.module('arounja.controllers')
    .controller('ResourceCtrl', ['$rootScope', '$scope', '$log', 'Authentication', 'SearchCriteria', 'BackendApi', '$stateParams', 'StaticMap', 'Location', '$ionicTabsDelegate', '$ionicPopover', '$state', '$ionicHistory', '$ionicPopup', '$timeout', 'Analytics', 'DevTools', '$window', function ($rootScope, $scope, $log, Authentication, SearchCriteria, BackendApi, $stateParams, StaticMap, Location, $ionicTabsDelegate, $ionicPopover, $state, $ionicHistory, $ionicPopup, $timeout, Analytics, DevTools, $window) {
        window.scope = $scope;
        var initialLoad = true;
        $scope.resource = null;
        $scope.mapShow = false;
        $scope.streetViewCreated = false;
        $scope.map = {
            zoom: 16,
            options: {
                panControl: true,
                mapTypeControl: false,
                overviewMapControl: false,
                streetViewControl: false,
                zoomControl: true,
                draggable: true,
                scrollwheel: true
            },
            coords: {},
        };

        $scope.streetViewError = false;
        $scope.share = {};
        $scope.windowShow = true;

        $scope.init = function () {
            $scope.getResource();
        };

        $scope.getResource = function () {
            BackendApi.getResource($stateParams.resourceId, SearchCriteria)
                .then(function (result) {
                    Analytics.trackEvent('Resources', 'Viewed', result.post_title);

                    $window.document.title = result.post_title;

                    $scope.resource = result;
                    $scope.map.coords.latitude = result.location.lat;
                    $scope.map.coords.longitude = result.location.lng;
                    $scope.resource.coords = {};
                    $scope.resource.coords.latitude = result.location.lat;
                    $scope.resource.coords.longitude = result.location.lng;
                    $scope.getStaticMap();
                    $scope.createShareLinks();
                });
        };

		$scope.checkin = function() {
            BackendApi.submitCheckin($stateParams.resourceId)
                .then(function(result) {
					$scope.resource.checkins.push(result);
					$ionicPopup.show({
						template: '',
						title: 'Check-in Submitted',
						subTitle: 'Thank you, you are checked in at this location!',
						scope: $scope,
						cssClass: 'positive',
						buttons: [
							{ text: 'Okay' }
						]
					});
                });
		};

        $scope.submitRating = function($event) {
            if ($event.rating === 0 || initialLoad) {
                initialLoad = false;
                return;
            }
            BackendApi.submitRating($stateParams.resourceId, $event.rating).then(function(result) {
                var updatePopup = $ionicPopup.show({
                    template: '',
                    title: 'Rating Submitted',
                    subTitle: 'Thank you for rating this resource!',
                    scope: $scope,
                    cssClass: 'positive',
                    buttons: [
                        { text: 'Okay' }
                    ]
                });
            });
        };

        $scope.getStaticMap = function () {
            $timeout(function () {
                StaticMap.getStaticMap({
                    latitude: $scope.resource.location.lat,
                    longitude: $scope.resource.location.lng,
                    locationRequestSuccessful: true
                }, 17, {
                        width: $('.static-map').width(),
                        height: $('.static-map').height(),
                    })
                    .then(function (result) {
                        $scope.staticMap = result;
                    });
            }, 50);
        };

        $scope.openMap = function () {
            $ionicTabsDelegate.select(1);
        };

        $scope.openSuggestion = function () {
            $ionicTabsDelegate.select(4);
        };

        $scope.createStreetView = function () {
            if ($scope.streetViewCreated) {
                return;
            }
            var geocoder = new google.maps.Geocoder();

            var position;
            geocoder.geocode({
                'address': $scope.resource.location.address
            }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    position = results[0].geometry.location;
                    $scope.getPano(position);
                } else {
                    position = new google.maps.LatLng($scope.resource.coords.latitude, $scope.resource.coords.longitude);
                    $scope.getPano(position);
                }
            });
        };

        $scope.getPano = function (position) {
            var panorama = new google.maps.StreetViewPanorama(document.getElementById('pano'));
            var streetViewService = new google.maps.StreetViewService();
            streetViewService.getPanorama({
                location: position,
                source: google.maps.StreetViewSource.OUTDOOR
            }, function (data, status) {
                if (status == google.maps.StreetViewStatus.OK) {
                    var heading = google.maps.geometry.spherical.computeHeading(data.location.latLng, position);
                    panorama.setPano(data.location.pano);
                    panorama.setPov({
                        heading: heading,
                        pitch: 0
                    });
                    panorama.setVisible(true);
                    $scope.streetViewCreated = true;
                } else {
                    $scope.streetViewError = true;
                }
            });
        };

        $ionicPopover.fromTemplateUrl('templates/category-popover.html', {
            scope: $scope
        }).then(function (popover) {
            $scope.popover = popover;
        });

        $scope.openPopover = function ($event, e) {
            $scope.popover.show($event);
            BackendApi.getAir(e.id).then(function (result) {
                $scope.categoryTitle = result.name;
                $scope.categoryDescription = result.description;
                $scope.categoryID = result.term_id;
            });
        };

        $scope.closePopover = function () {
            $scope.popover.hide();
        };

        $scope.$on('$destroy', function () {
            $scope.popover.hide();
        });

        $scope.$on('popover.hidden', function () {
            $scope.categoryTitle = '';
            $scope.categoryDescription = '';
        });

        $scope.$on('$stateChangeStart', function(event) {
            if ($ionicTabsDelegate.selectedIndex() === 4) {
                $ionicTabsDelegate.select(0);
                event.preventDefault();
                return;
            }
            $window.document.title = $window.location.host + $window.location.pathname + '#/app/results';
        });

        $scope.search = function (airID) {
            SearchCriteria.data.airs = [airID];
            $scope.popover.hide();
            $rootScope.$broadcast('searchReload');
            $state.go('app.results', {}, {
                reload: true,
                inherit: false,
                notify: true
            }).then(function() {
            });
        };

        $scope.openDirections = function () {
            if (!angular.equals(ionic.Platform.device(), {})) {
                launchnavigator.navigate($scope.resource.location.address);
            } else {
                window.open('https://www.google.com/maps/dir//' + $scope.resource.location.address, '_system');
            }
        };

        $scope.shareResource = function () {
            if (!angular.equals(ionic.Platform.device(), {})) {
                alert('share device')
                $scope.shareDevice();
            } else {
                $scope.shareBrowser();
            }
        };

        $scope.bookmarkResource = function () {
            BackendApi.addBookmark($scope.resource.ID).then(function() {
                $ionicPopup.show({
                    template: '',
                    title: 'Resource Bookmarked',
                    subTitle: 'Thank you, this resource has been added to your bookmarks!',
                    scope: $scope,
                    cssClass: 'positive',
                    buttons: [
                        { text: 'Okay' }
                    ]
                });
            });
        };

        $scope.shareBrowser = function () {
            var alertPopup = $ionicPopup.alert({
                title: 'Share Resource',
                templateUrl: 'templates/share.html',
                cssClass: 'positive',
                scope: this,
                buttons: [{
                    text: 'Done'
                }]
            });

            DevTools.log.add([
                'Share via Browser'
            ])
        };

        $scope.shareDevice = function () {
            // this is the complete list of currently supported params you can pass to the plugin (all optional)
            var options = {
                message: 'Arounja: ' + $scope.resource.post_title, // not supported on some apps (Facebook, Instagram)
                subject: 'View ' + $scope.resource.post_title + ' on Arounja', // fi. for email
                url: $scope.resource.guid
            };

            var onSuccess = function (result) {
                $scope.updateReferrals();
            };

            var onError = function (msg) {
                $log.log("Sharing failed with message: " + msg);
            };

            DevTools.log.add([
                'Share via Device', options
            ])

            window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
        };

        $scope.createShareLinks = function () {
            $scope.share.email = 'mailto:?to=&body=View ' + $scope.resource.post_title + ' on Arounja.%0D%0A' + $scope.resource.guid + '&subject=' + $scope.resource.post_title + ' shared on Arounja';
            $scope.share.url = encodeURIComponent($scope.resource.guid);
        };

        $scope.updateReferrals = function (source, url) {
            if(url)
                $scope.open(url);

            Analytics.trackEvent('Resources', 'Referred', source, $scope.resource.ID);
            //$scope.ga.event('resources', 'share', source, $scope.resource.ID);
            BackendApi.updateReferralCount($scope.resource.ID);
        };

        $scope.selectTab = function (tab, then){
            Analytics.trackEvent('Tabs', 'Clicked', tab);
            if(then)
                return then();
        }

        $scope.init();
    }]);
